import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Layout from "../components/Layout"
import Hero from "../sections/hero/hero"
import ClientExp from "../sections/clients_exp/clients_exp"
import Features from "../sections/features/features"
import Services from "../sections/services/services"
import About from "../sections/about/about"
import LogoSlides from "../sections/logo_slides/logo_slides"
import RecentWork from "../sections/works/recentWork"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import * as client_exp_styles from "../sections/clients_exp/clients_exp.module.scss"
import * as feature_styles from "../sections/features/features_index.module.scss"
import * as icon_background from "../sections/features/icon_background.module.scss"
import * as services_styles from "../sections/services/services.module.scss"
import * as logo_slide_styles from "../sections/logo_slides/logo_slides.module.scss"
import * as about_styles from "../sections/about/about.module.scss"
import * as works_styles from "../sections/works/works_index.module.scss"
import { getSeo } from "../utils/seo"

import CtoData from "../utils/cto-queries"
import { cto } from "../utils/seo"

export default function Cto() {
  const {
    hero,
    features,
    about,
    problems,
    services,
    features2,
    testimonial,
    formula,
    logoSlider,
  } = CtoData()

  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo
        title={cto.title}
        keywrods={cto.keywrods}
        description={cto.description}
      /> */}
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={features.slug?.current}
      />
      <Features features={features} styles={feature_styles} />
      <About content={about} styles={about_styles} />
      <Features features={problems} styles={feature_styles} />
      {/* <RecentWork works={problems} styles={works_styles} /> */}
      <Services content={services} styles={services_styles} />
      <Features features={features2} styles={feature_styles} />
      <ClientExp content={testimonial} styles={client_exp_styles} />
      <Features features={formula} styles={icon_background} />
      <LogoSlides content={logoSlider} styles={logo_slide_styles} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = CtoData()
  return getSeo(seo)
}
