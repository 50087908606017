// extracted by mini-css-extract-plugin
export var appSlider = "logo_slides-module--app-slider--2xx0D";
export var contentWrapper = "logo_slides-module--content-wrapper--2GRQE";
export var defaultContainer = "logo_slides-module--default-container--2KvsO";
export var featureSlider = "logo_slides-module--feature-slider--1DQig";
export var innerPagesSlider = "logo_slides-module--inner-pages-slider--1fVqX";
export var leftAppSlide = "logo_slides-module--left-app-slide--3T16G";
export var logoSlide = "logo_slides-module--logo-slide--3L2vR";
export var logoSlideContainer = "logo_slides-module--logo-slide-container--wNlUI";
export var logoSlideItem = "logo_slides-module--logo-slide-item--hX6vF";
export var logoSlideWrapper = "logo_slides-module--logo-slide-wrapper--1bmIU";
export var logoSlider = "logo_slides-module--logo-slider--2gzNF";
export var phoneAppSelect = "logo_slides-module--phone-app-select--wkCPm";
export var phoneCaseSelect = "logo_slides-module--phone-case-select--2DaYk";
export var rightAppSlide = "logo_slides-module--right-app-slide--2pgeo";
export var scroll = "logo_slides-module--scroll--1XW-3";
export var slickActive = "logo_slides-module--slick-active--304RG";
export var slickArrow = "logo_slides-module--slick-arrow--1xEzW";
export var slickCenter = "logo_slides-module--slick-center--2bKLY";
export var slickDots = "logo_slides-module--slick-dots--2Ve31";
export var slickNext = "logo_slides-module--slick-next--1zDp5";
export var slickPrev = "logo_slides-module--slick-prev--1oVuh";
export var slickSlide = "logo_slides-module--slick-slide--3vu5f";
export var slickSlider = "logo_slides-module--slick-slider--1RX2X";
export var slickTrack = "logo_slides-module--slick-track--XgVPh";
export var slidesContainer = "logo_slides-module--slides-container--3Hkc5";
export var solvingSlider = "logo_slides-module--solving-slider--1rqSK";
export var subtitle = "logo_slides-module--subtitle--TGZX9";
export var title = "logo_slides-module--title--3Y8Hj";
export var titleWrapper = "logo_slides-module--title-wrapper--3clS9";