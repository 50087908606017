import React, { useEffect, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import { useAnimateOnScreen } from "../../utils/useAnimateOnScreen"

export default function LogoSlides(props) {
  const { content, styles } = props
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 767 })

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  }
  const { ref, isIntersecting } = useAnimateOnScreen(options)
  const returnTitle = () => {
    return (
      content?.title && (
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>{content.title}</h2>
        </div>
      )
    )
  }
  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.parent.document.documentElement.style.setProperty(
          "--amount",
          ref.current
            ? `-${ref.current.scrollWidth - ref.current.clientWidth}px`
            : `0px`
        )
        window.parent.document.documentElement.style.setProperty(
          "--time",
          ref.current
            ? `${Math.floor(
                (ref.current.scrollWidth / 1000) * content?.logos.length
              )}s`
            : `0s`
        )
      }
    }, 1000)
  }, [content])

  const returnSlider = () => {
    return (
      content?.logos &&
      content?.logos.length > 0 && (
        <div className={styles.contentWrapper}>
          <div
            style={
              isIntersecting
                ? { animationPlayState: "running" }
                : { animationPlayState: "paused" }
            }
            className={styles.slidesContainer}
          >
            {content?.logos.map((item, index) => (
              <div key={index} className={styles.logoSlideItem}>
                <img src={item.logo.asset.url}></img>
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  return (
    <section
      ref={ref}
      style={{
        backgroundColor: content?.backgroundColor
          ? content.backgroundColor.hex
          : "",
      }}
      className={styles.logoSlide}
    >
      <div className={`default-container ${styles.logoSlideWrapper}`}>
        <div className={styles.logoSlideContainer}>
          {returnTitle()}
          {returnSlider()}
        </div>
      </div>
    </section>
  )
}
