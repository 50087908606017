import { graphql, useStaticQuery } from "gatsby"

export default function CtoData() {
  return useStaticQuery(graphql`
    query queryCto {
      seo: sanitySeo(slug: { current: { eq: "for-ctos" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "helping-ctos" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      features: sanityFeatures(
        slug: { current: { eq: "benefits-of-outsourcing" } }
      ) {
        id
        slug {
          current
        }
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      about: sanityAbout(slug: { current: { eq: "why-work-with-us" } }) {
        description
        title
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        image {
          asset {
            url
          }
        }
        isHintsAnimation
      }
      problems: sanityFeatures(slug: { current: { eq: "problems-we-solve" } }) {
        features {
          backgroundColor {
            hex
          }
          title
          icon {
            asset {
              url
            }
          }
          info
          isInfoBoxBackground
        }
        id
        title
        slide
        backgroundColor {
          hex
        }
        hints {
          parts
        }
        isHintsAnimation
      }
      services: sanityFeatures(
        slug: { current: { eq: "typical-scenarios-cto" } }
      ) {
        id
        hints {
          parts
        }
        slide
        title
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      features2: sanityFeatures(
        slug: { current: { eq: "our-solutions-cto" } }
      ) {
        features {
          backgroundColor {
            hex
          }
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        id
        title
        slide
        backgroundColor {
          hex
        }
        hints {
          parts
        }
        isHintsAnimation
      }
      testimonial: sanityTestimonials(slug: { current: { eq: "light" } }) {
        title
        testimonials {
          author {
            name
            position
            image {
              asset {
                url
              }
            }
          }
          text
        }
        shadowBackgroundColor {
          hex
        }
        cardBackgroundColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
      formula: sanityFeatures(
        slug: { current: { eq: "cto-our-formula-for-success" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          backgroundColor {
            hex
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      logoSlider: sanityLogoSlider(
        slug: { current: { eq: "logo-slider-cto" } }
      ) {
        title
        backgroundColor {
          hex
        }
        logos {
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  `)
}
